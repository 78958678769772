<template>
  <div class="container mx-auto px-3 max-w-screen-lg">
    <LoadingScreen v-if="isLoading" />

    <fade-transition :duration="{enter: 300, leave: 300}" mode="out-in">

      <p
        v-if="showMessage"
        class="p-2 w-full rounded-md text-white" 
        :class="[!showError ? 'bg-green-600 px-16 py-16' : 'bg-red-700']"
      >            
        <span v-if="!showError" class="block px-3 font-semibold pb-6">
          <font-awesome-icon icon="check" class="text-5xl p-2 mx-auto my-6 text-green-600 bg-white rounded-full" /><br>
          Пароль успешно изменён!
        </span>
      </p>

      <div v-if="!isLoading">
        <div class="bg-white shadow-md px-20 py-6 rounded-lg mt-8 sm:w-full md:w-3/4 lg:w-2/3 m-auto">
          <h1 
            class="block text-xl text-secondary text-center font-semibold pt-3 pb-6 pr-2 md:pr-4 lg:pr-8"
            style="line-height: 1.2"
          >
            Восстановление пароля
          </h1>
          <div v-if="status === 'done'">
            <div class="mb-3 relative flex">
              <div class="w-1/3 pl-3 pt-2 pr-6 text-gray-500 text-sm text-left">
                Введите новый пароль:
              </div>
              <input
                aria-label="New password"
                id="newPassword"
                v-model="newPassword"
                @change="$v.newPassword.$touch"
                v-bind:class="{ 'focus:border-red-500 text-red-700' : $v.newPassword.$error,
                  'focus:border-green-300 text-primary-500' : !$v.newPassword.$error }"
                class="appearance-none relative block w-2/3 px-3 py-2 border border-gray-300 placeholder-gray-500
                text-gray-900 rounded-md focus:outline-none focus:shadow-outline-blue focus:z-10 sm:text-sm
                sm:leading-5"
                placeholder="Новый пароль"
              />

              <!-- Input field validation icon with animation -->
              <slide-x-left-transition :duration="300" mode="out-in">
                <div v-if="$v.newPassword.$dirty" class="absolute right-0 top-0 mr-4 ml-auto mt-2">
                  <font-awesome-icon v-if="!$v.newPassword.$error" icon="check" class="text-green-700"/>
                  <font-awesome-icon v-if="$v.newPassword.$error" icon="times" class="text-red-700"/>
                </div>
              </slide-x-left-transition>

              <!-- Error message -->
              <collapse-transition :duration="300" mode="out-in">
                <div v-if="$v.newPassword.$error" class="text-red-700 mt-0 text-sm">
                  <span v-if="!$v.newPassword.minLength || !$v.newPassword.required">Пароль должен быть минимум 6 символов!</span>
                </div>
              </collapse-transition>
            </div>

            <div class="mb-3 relative flex">
              <div class="w-1/3 pl-3 pt-2 pr-6 text-gray-500 text-sm text-left">
                Подтвердите пароль:
              </div>
              <input
                aria-label="Confirm password"
                id="confPassword"
                v-model="confirmPassword"
                @change="$v.confirmPassword.$touch"
                v-bind:class="{ 'focus:border-red-500 text-red-700' : $v.confirmPassword.$error,
                  'focus:border-green-300 text-primary-500' : !$v.confirmPassword.$error }"
                class="w-2/3 appearance-none relative block px-3 py-2 border border-gray-300 placeholder-gray-500
                text-gray-900 rounded-md focus:outline-none focus:shadow-outline-blue focus:z-10 sm:text-sm
                sm:leading-5"
                placeholder="Подтвердите пароль"
              />

              <!-- Input field validation icon with animation -->
              <slide-x-left-transition :duration="300" mode="out-in">
                <div v-if="$v.confirmPassword.$dirty" class="absolute right-0 top-0 mr-4 ml-auto mt-2">
                  <font-awesome-icon v-if="!$v.confirmPassword.$error" icon="check" class="text-green-700"/>
                  <font-awesome-icon v-if="$v.confirmPassword.$error" icon="times" class="text-red-700"/>
                </div>
              </slide-x-left-transition>

              <!-- Error message -->
              <collapse-transition :duration="300" mode="out-in">
                <div v-if="$v.confirmPassword.$error" class="text-red-700 mt-0 text-sm">
                  <span v-if="!$v.confirmPassword.sameAsPassword || !$v.confirmPassword.required">Пароли не совпадают!</span>
                </div>
              </collapse-transition>
            </div>

            <button
              @click="sendClick()"
              type="submit"
              class="group mr-4 mt-4 relative w-1/3 justify-center py-2 px-4 text-sm leading-5 font-medium rounded-md
              text-white bg-primary-600 hover:bg-primary-400 active:bg-primary-600 transition duration-150 ease-in-out"
            >
              Отправить
            </button>
          </div>

          <div v-if="status === 'error'">
            <b>Ошибка!</b><br><br>
            Ссылка восстановления пароля не верная или восстановление пароля уже производилось.
          </div>
        </div>
      </div>
    </fade-transition>
  </div>
</template>

<script>
import LoadingScreen from '@/components/LoadingScreen80vh.vue'
import { FadeTransition } from 'vue2-transitions'
import { mapActions } from 'vuex'

import { validationMixin } from 'vuelidate'
import { required, sameAs, minLength, requiredIf, helpers } from 'vuelidate/lib/validators'
import { SlideXLeftTransition, CollapseTransition } from 'vue2-transitions'

export default {
  name: 'PasswordRecovery',
  mixins: [validationMixin],
  components: { LoadingScreen, FadeTransition, SlideXLeftTransition, CollapseTransition },
  props: { token: String },
  data: function() {
    return {
      isLoading: true,
      showMessage: false,
      status: null,
      newPassword: null,
      confirmPassword: null
    }
  },
  methods: {
    ...mapActions({ 
      checkToken: 'user/passwordRecoveryCheckToken',
      changePassword: 'user/passwordRecoveryChangePassword'
    }),
    sendClick() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      const data = {
        token: this.token,
        password: this.newPassword
      }

      this.isLoading = true

      this.changePassword(data)
        .then(() => {
          this.showMessage = true
          setTimeout(() => { this.$router.push('/') }, 500)
        })
        .catch((error) => {

        })
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  created() {
    this.checkToken(this.token)
      .then(() => {
        this.status = 'done'
      })
      .catch((error) => {
        this.status = 'error'
      })
      .finally(() => {
        this.isLoading = false
      })
  },
  validations: {
    newPassword: {
      required,
      minLength: minLength(6)
    },
    confirmPassword: {
      required,
      minLength: minLength(6),
      sameAsPassword: sameAs(function() {
          return this.newPassword
        }
      )
    }
  },
}
</script>
